.App {
  text-align: center;
}

.view-container {
  text-align: left;
  padding:50px;
}

ul {padding:0px;}
ul li {list-style: none;}

.text-truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/** Loader **/
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/*! CSS Used keyframes */
@-webkit-keyframes spinner-border{to{-webkit-transform:rotate(360deg);transform:rotate(360deg);}}
@keyframes spinner-border{to{-webkit-transform:rotate(360deg);transform:rotate(360deg);}}

/** tweetCard **/
.tweetCard {
  margin: 10px 0;
}

.tweetCard img.avatar {
  margin-top:10px;
  max-height: 100px;
  opacity: 0.5;
}
.tweetCard .timeInterval {
  float: right;
  background: none;
  font-weight: normal;
  color: #ccc;
  font-size: 14px;
}
.tweetCard .source {

}
.tweetCard .twitterId {
  padding:0 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
